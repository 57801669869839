/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { searchPluginConfigContext } from "@whitespace/gatsby-plugin-search/src/contexts";
import SearchBox from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchBox";
import SearchHit from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchHit";
import SearchHits from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchHits";
import SearchPagination from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchPagination";
import SearchProvider from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchProvider";
import SearchRangeInput from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchRangeInput";
import SearchSortBy from "@whitespace/gatsby-theme-wordpress-basic/src/components/algolia/SearchSortBy";
import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Configure } from "react-instantsearch-hooks-web";

// import SearchDebug from "./SearchDebug";

ContentTypeArchive.propTypes = {
  contentType: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
};

export default function ContentTypeArchive({ contentType }) {
  // const {
  //   indexUiState,
  //   setIndexUiState,
  //   uiState,
  //   setUiState,
  //   results,
  //   scopedResults,
  //   refresh,
  //   use,
  // } = useInstantSearch();

  const { t, i18n } = useTranslation();
  const searchPluginConfig = useContext(searchPluginConfigContext);
  const archiveConfig = useMemo(() => {
    let archiveConfig = searchPluginConfig?.archives?.[contentType.name] || {};
    return {
      searchBox: false,
      sortBy: archiveConfig.searchBox ? "relevance" : "publish_date",
      ...archiveConfig,
      facets: {
        dates: true,
        ...archiveConfig?.facets,
      },
    };
  }, []);

  const getIndexItems = (indexName) =>
    archiveConfig.sortBy === "relevance"
      ? [{ label: t("search.sortBy.relevance"), value: indexName }]
      : [
          {
            label: t("search.sortBy.publishDate"),
            value: `${indexName}_publish_date`,
          },
        ];

  return (
    <SearchProvider routing={true} skipSearchIf={() => false}>
      {({ indexName }) => (
        <div
          css={css`
            display: grid;
            grid-template-columns: 1fr;
            gap: 1rem;
          `}
        >
          <Configure filters={`language:${i18n.language}`} />
          <Configure filters={`contentType.name:${contentType.name}`} />
          {!!archiveConfig.searchBox && <SearchBox searchAsYouType={false} />}
          <div
            css={css`
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
              gap: 1rem;
            `}
          >
            {!!archiveConfig.facets.dates && (
              <SearchRangeInput
                css={css`
                  grid-column: span 2;
                `}
                attribute="dates.numeric"
              />
            )}
          </div>
          <div
            css={css`
              display: grid;
              grid-template-columns: 1fr max-content;
              align-items: end;
            `}
          >
            <SearchSortBy
              label={t("sortBy")}
              items={getIndexItems(indexName)}
            />
          </div>
          {/* <SearchDebug /> */}
          <SearchHits hitComponent={SearchHit} />
          <SearchPagination />
        </div>
      )}
    </SearchProvider>
  );
}
